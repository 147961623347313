import React, { Component } from 'react';
import { Button, Grid, Input, TextField, CircularProgress} from '@material-ui/core';
import Camel from './Camel';
import './EditCamel.scss';

export default class EditCamel extends Component {

    constructor(props) {
        super(props);

        this.state = {
            camelName: this.props.game.camels[this.props.camelId].name,
            camelColor: this.props.game.camels[this.props.camelId].colorHex,
            isUpdating: false,
        }
    }

    handleSave = () => {
        this.setState({isUpdating: true});
        this.props.onClose(this.props.camelId, this.state.camelName, this.state.camelColor);
    }

    render() {
        return (
            <div className="camel-edit-dialog">
                <Camel name={this.state.camelName} color={this.state.camelColor} editable />
                <Grid container justify="center">
                    <Input
                        value={this.state.camelColor}
                        type="color"
                        className="camel-color"
                        onChange={(event) => this.setState({camelColor: event.target.value})}
                        >
                    </Input>
                    <TextField
                        label="Name"
                        className="camel-name"
                        variant="outlined"
                        value={this.state.camelName}
                        onChange={(event) => this.setState({camelName: event.target.value})}
                    >
                    </TextField>
                </Grid>
                <Button 
                    className="update-camel-btn" 
                    variant="contained"
                    disabled={this.state.isUpdating}
                    onClick={this.handleSave}
                >
                    {this.state.isUpdating ?
                        (<CircularProgress />) :
                        ('Save Camel')
                    }
                </Button>
            </div>
        )
    }
}