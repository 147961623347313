import React, {useState} from 'react';
import { Button, Grid} from '@material-ui/core';
import Camel from './Camel';
import './Bet.scss';

export default function Bet(props) {
    const [camel, setCamel] = useState(-1);
    const camelButtons = [];
    props.game.camels.forEach((gameCamel, i) => {
        const className = camel === i ? "select-camel-btn" : "camel-btn";
        const key = `bet_${i}`
        const canbet = props.availableBets.map(b => b.camel).includes(i);
        const value = canbet ? `$${props.availableBets.find(b => b.camel === i).value}` : "";
    
        camelButtons.push((
            <Button 
                variant="contained"
                className={className}
                key={key}
                disabled={!canbet}
                onClick={()=>{setCamel(i)}}>
                    <Camel
                        name={gameCamel.name}
                        color={gameCamel.colorHex}
                        solo
                    />
                    {gameCamel.name}<br/>{value}
            </Button>));
    });
    
    return (<div className="bet-container">
        <Grid container justify="center">
            <Grid container justify="center">
                {camelButtons}
            </Grid>
            <Button 
                className="place-bet-btn" 
                variant="contained" 
                disabled={camel === -1}
                onClick={()=> {props.cb(camel)}}>
                    Place Bet
            </Button>
        </Grid>
    </div>)
}