import React, { Component, Fragment } from 'react';
import { Fab, Icon } from '@material-ui/core';
import BetInfoDialog from './BetInfoDialog';
import GameSettingsDialog from './GameSettingsDialog';
import GameDialog from '../GameDialog';
import { GameLog } from './GameLog';
import Scoreboard from '../Scoreboard';

import './FloatingActions.scss';

const GAME_SETTINGS_LS_KEY = 'gameSettings';

class FloatingActionsComponent extends Component {
    constructor(props) {
        super(props);

        const gameSettings = {
            isFlipped: false,
            positionY: 1,
            size: 4,
            ...JSON.parse(localStorage.getItem(GAME_SETTINGS_LS_KEY)) // Overwrite default values with local storage
        };

        this.state = {
            isGameLogVisible: false,
            isScoreboardVisible: false,
            isBetInfoVisible: false,
            isGameSettingsVisible: false,
            ...gameSettings
        };
    }

    updateGameSettings = (update) => {
        const game_settings = {
            isFlipped: this.state.isFlipped,
            positionY: this.state.positionY,
            size: this.state.size,
            ...update
        };
        
        localStorage.setItem(GAME_SETTINGS_LS_KEY, JSON.stringify(game_settings));
        this.setState(update);
    }

    updateFlipped = (event) => {
        this.updateGameSettings({
            isFlipped: event.target.checked
        });
    }

    updatePositionY = (event, value) => {
        this.updateGameSettings({
            positionY: value
        });
    }

    updateSize = (event, value) => {
        this.updateGameSettings({
            size: value
        });
    }

    render() {
        const fabStyle = {
            'bottom': `${this.state.positionY}rem`
        };
        if (this.state.isFlipped) {
            fabStyle.left = `${this.state.size + 1}rem`;
        } else {
            fabStyle.right = '1rem';
        }

        const getButtonStyle = (index) => {
            return {
                'width': `${this.state.size}rem`,
                'height': `${this.state.size}rem`,
                'minHeight': `${this.state.size}rem`,
                'fontSize': `${this.state.size/2}rem`,
                'bottom': `${(this.state.size + 1)*index}rem`
            }
        }

        return (
            <Fragment>
                <div
                    className="fab-btn-container"
                    style={fabStyle}
                >
                    <Fab
                        style={getButtonStyle(3)}
                        onClick={() => {this.setState({isGameSettingsVisible: true})}}
                    >
                        <Icon>settings</Icon>
                    </Fab>
                    <Fab
                        style={getButtonStyle(2)}
                        onClick={() => {this.setState({isGameLogVisible: true})}}
                        >
                        <Icon>grading</Icon>
                    </Fab>
                    <Fab
                        style={getButtonStyle(1)}
                        onClick={() => {this.setState({isScoreboardVisible: true})}}
                        >
                        <Icon>attach_money</Icon>
                    </Fab>
                    <Fab
                        style={getButtonStyle(0)}
                        onClick={() => {this.setState({isBetInfoVisible: true})}}
                        >
                        <Icon>ballot</Icon>
                    </Fab>
                </div>

                <GameDialog
                    isOpen={this.state.isGameLogVisible}
                    onClose={() => {this.setState({isGameLogVisible: false})}}
                    title='Game Log'
                >
                    <GameLog game={this.props.game} playerColorMap={this.props.playerColorMap}/>
                </GameDialog>

                <GameDialog
                    isOpen={this.state.isScoreboardVisible}
                    onClose={() => {this.setState({isScoreboardVisible: false})}}
                    title='Scoreboard'
                >
                    <Scoreboard game={this.props.game}/>
                </GameDialog>

                <BetInfoDialog
                    isOpen={this.state.isBetInfoVisible}
                    onClose={() => {this.setState({isBetInfoVisible: false})}}
                    game={this.props.game}
                />
                <GameSettingsDialog
                    isOpen={this.state.isGameSettingsVisible}
                    onClose={() => {this.setState({isGameSettingsVisible: false})}}
                    isFlipped={this.state.isFlipped}
                    positionY={this.state.positionY}
                    size={this.state.size}
                    updateFlipped={this.updateFlipped}
                    updatePositionY={this.updatePositionY}
                    updateSize={this.updateSize}
                />
            </Fragment>
        )
    }
}
export default FloatingActionsComponent