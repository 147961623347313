import React from 'react';
import { Button, Grid, Typography, Grow } from '@material-ui/core';
import FullscreenButton from './Fullscreen';
import './Game.scss';

export default function Tutorial(props) {
    const transitionTimeout = {
        appear: 0,
        enter: 2000
    };

    return (
        <div className="game-container game-not-started">
            <Grid container alignItems="center" justify="space-evenly" direction="column" className="guide-container">
                <Typography variant="h2" className="guide-title">
                    How To Play
                </Typography>
                <Grow in={true} timeout={transitionTimeout}>
                    <Typography variant="h5">
                        <img src="/assets/logo.png" alt="logo"></img>
                        <b>Setup: </b>
                        Earn the most money betting on camels as they race around the track
                    </Typography>
                </Grow>
                <Grow in={true} timeout={transitionTimeout}  style={{transitionDelay: 2000}}>
                    <Typography variant="h5">
                        <img src="/assets/logo.png" alt="logo"></img>
                        <b>Move: </b>
                        Moves one camel one, two, or three spaces. A leg finishes after five moves - one for each camel. All camels in a stack above the moving camel all move together. Earn $1 for each move you make
                    </Typography>
                </Grow>
                <Grow in={true} timeout={transitionTimeout} style={{transitionDelay: 4000}}>
                    <Typography variant="h5">
                        <img src="/assets/logo.png" alt="logo"></img>
                        <b>Bet on leg: </b>
                        Make a bet on which camel will be in the lead at the end of the leg. Earn full bet value ($5, $3, or $2) for first place, $1 for second, or lose $1 for third, fourth, or fifth.
                    </Typography>
                </Grow>
                <Grow in={true} timeout={transitionTimeout} style={{transitionDelay: 6000}}>
                    <Typography variant="h5">
                        <img src="/assets/logo.png" alt="logo"></img>
                        <b>Bet on overall winner or loser: </b>
                        Make a bet on which camel will cross the finish line first, or bet on the camel in last place when the finish line is crossed.
                    </Typography>
                </Grow>
                <Grow in={true} timeout={transitionTimeout} style={{transitionDelay: 8000}}>
                    <Typography variant="h5">
                        <img src="/assets/logo.png" alt="logo"></img>
                        <b>Desert: </b>
                        Place a boost - pushing camels forward 1 square - or a trap - pushing camels back 1 square - on the track. Earn $1 when a camel lands on the desert. Deserts are removed at the end of each leg.
                    </Typography>
                </Grow>

                <Button variant="contained"
                        className="skip-guide-btn"
                        onClick={() => props.cb()}>
                    Got it, let's go!
                </Button>
            </Grid>
            <FullscreenButton white></FullscreenButton>
        </div>
    );
}