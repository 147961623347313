import React, { Component } from 'react';
import { MysteryCamel } from './CamelPersonalizations';
import './Camel.scss';

/**
 * PROPS:
 *  - name : Name of the camel, Ex: 'Dash'
 *  - color : Color of the camel, Ex: '#2f3c55'
 *  - stackIndex : Where in the stack the camel is (default is 0)
 *  - trackIndex : Which square the track the camel is (default is 0)
 *  - style : Style override for the camel SVG
 *  - solo : Flag where, if present, will not render the camel in a stack.
 *  - editable : Flag where, if present, will render the camel for editing
 */
export default class CamelComponent extends Component {
    render() {
        const cp = {...MysteryCamel};
        const stackIndex = this.props.stackIndex ? this.props.stackIndex : 0;
        const trackIndex = this.props.trackIndex ? this.props.trackIndex : 0;

        if (this.props.name) {
            cp.name = this.props.name;
        }
        if (this.props.color) {
            cp.colorHex = this.props.color;
        }

        let camelStyle = {};
        let camelNameStyle = {};
        let camelHatStyle = {};
        let camelHat = null;
        let camelHatClassName = '';

        // Easter Egg
        if (["trump","donald"].includes( cp.name.toLowerCase() )) {
            cp.colorHex = "#f79239"; // Cheetos
            camelHatClassName = 'donald';
            camelHat = (
                <svg viewBox="0 0 129 78">
                    <path id="Selection"
                        fill="yellow"
                        d="M 31.65,11.00
                        C 34.50,2.05 42.66,-0.19 51.00,0.01
                            61.53,0.27 79.34,11.09 87.00,18.09
                            91.79,22.47 93.39,25.09 100.00,26.84
                            110.20,29.53 120.16,23.23 128.79,19.00
                            129.00,21.30 129.11,24.84 128.79,27.00
                            127.57,31.18 121.13,41.45 118.33,45.00
                            104.93,62.04 79.51,72.74 58.00,73.00
                            49.31,73.10 42.02,73.10 34.00,69.01
                            28.59,66.25 24.06,60.75 20.00,60.85
                            10.94,61.07 13.04,75.31 5.00,77.29
                            -2.72,79.20 1.98,70.01 2.80,67.00
                            3.05,64.91 3.02,62.16 2.80,60.00
                            2.92,51.95 -0.45,43.02 1.39,35.00
                            4.33,22.20 19.57,19.66 30.00,24.00
                            30.11,21.51 30.98,13.11 31.65,11.00 Z" />
                </svg>
            );
        }
        
        if (this.props.solo) {
            camelStyle = {
                'fill': cp.colorHex,
            };
            camelHatStyle = {
                width: '1rem',
                top: '-0.6rem',
                left: '1.4rem'
            };
        }
        else if (this.props.editable) {
            camelStyle = {
                'fill': cp.colorHex,
            };
            camelNameStyle = {
                'top': '1rem',
                'left': '0',
                'fontSize': '0.75rem',
                'opacity': 1
            };
        }
        else {
            // Shift camels up if they are later in the stack
            const camelShiftUp = `-${1.5 * stackIndex}rem`;
            camelHatStyle.top = `-${1.5 * stackIndex + 0.3}rem`;
            
            // Shift camel stack to the right if on an even square
            const camelShiftLeft = `${trackIndex % 2 === 0 ? 2.4 : 1.8}rem`;
            camelHatStyle.right = `${trackIndex % 2 === 0 ? -1.6 : -1.1}rem`;

            // Shift name to be in the middle of a camel
            const nameShiftUp = `${0.9 + -1.5 * stackIndex}rem`;
            const nameShiftLeft = `${trackIndex % 2 === 0 ? 2 : 1.4}rem`;

            // Flip camel to face direction it's moving
            let camelTransform = '';
            if (trackIndex <= 2 || trackIndex >= 15) {
                camelTransform = 'rotateY(180deg)';
                camelHatStyle.transform = camelTransform;
                camelHatStyle.left = `${trackIndex % 2 === 0 ? 2.1 : 1.5}rem`;
            }

            camelStyle = {
                'position': 'absolute',
                'width': '4rem',
                'height': '2.6rem',
                'top': camelShiftUp,
                'left': camelShiftLeft,
                'zIndex': `${1 + 2*stackIndex}`,
                'transform': camelTransform,
                'fill': cp.colorHex,
            };

            camelNameStyle = {
                'top': nameShiftUp,
                'left': nameShiftLeft
            };

            camelHatStyle.width = '1.5rem';
            camelHatStyle.zIndex = `${2 + 2*stackIndex}`;
            camelHatStyle.transform = camelTransform;
        }

        return (
            <div className="camel-container">
                <div
                    className={`camel-hat ${camelHatClassName}`}
                    style={{
                        display: camelHat ? 'inherit': 'none',
                        ...camelHatStyle
                    }}
                    onClick={this.props.onClick}
                >
                    {camelHat}
                </div>
                <svg
                    className="camel"
                    style={{
                        ...camelStyle,
                        ...this.props.style
                    }}
                    onClick={this.props.onClick}
                    viewBox="0 0 500 326"
                >
                    <path
                        d="M 243.96,18.21
                            C 253.70,26.11 268.88,44.38 278.12,54.58
                            284.85,62.01 297.36,75.00 305.00,80.99
                            310.79,85.52 316.28,89.40 324.00,88.82
                            350.54,86.81 355.46,61.09 361.36,40.00
                            363.48,32.46 367.09,17.96 375.01,14.89
                            380.74,12.68 390.27,16.56 394.91,19.99
                            394.91,19.99 404.00,28.16 404.00,28.16
                            406.13,29.49 410.51,30.95 413.00,31.48
                            413.00,31.48 426.00,33.31 426.00,33.31
                            426.00,33.31 461.00,40.54 461.00,40.54
                            461.00,40.54 478.00,43.04 478.00,43.04
                            492.08,47.18 499.94,57.40 500.00,72.00
                            500.04,81.23 496.20,97.38 492.69,106.00
                            491.20,109.64 488.65,115.06 485.79,117.73
                            483.08,120.26 479.29,121.20 476.00,122.81
                            467.39,127.01 462.96,129.83 453.00,130.83
                            445.31,131.60 431.08,130.15 425.89,137.21
                            422.55,141.75 423.44,152.40 422.56,158.00
                            419.86,175.32 419.60,196.74 410.55,212.00
                            405.19,221.03 396.19,229.61 387.00,234.69
                            374.59,241.55 358.90,246.21 352.33,260.00
                            346.49,272.24 346.29,292.97 337.37,309.00
                            333.44,316.06 326.22,323.86 318.00,325.61
                            304.50,328.48 288.43,309.91 280.30,301.09
                            280.30,301.09 255.17,275.01 255.17,275.01
                            255.17,275.01 244.28,263.00 244.28,263.00
                            244.28,263.00 236.83,254.29 236.83,254.29
                            228.03,245.78 214.98,238.05 203.00,235.24
                            184.75,230.95 164.31,237.52 151.32,250.87
                            143.21,259.21 138.76,268.62 134.00,279.00
                            134.00,279.00 129.01,290.00 129.01,290.00
                            124.66,300.48 119.67,316.22 106.00,316.86
                            102.89,317.00 96.98,315.30 94.00,314.19
                            81.15,309.43 82.24,301.69 67.00,289.89
                            53.78,279.65 39.15,273.12 28.26,259.99
                            20.49,250.61 17.33,242.10 13.19,231.00
                            7.00,214.42 0.03,191.69 0.00,174.00
                            0.00,174.00 1.09,143.00 1.09,143.00
                            1.09,143.00 2.00,133.00 2.00,133.00
                            2.22,113.62 4.34,80.06 13.13,63.00
                            16.26,56.92 19.20,53.77 24.00,49.00
                            33.01,40.05 39.91,34.70 53.00,35.01
                            66.13,35.33 69.88,42.67 78.01,51.00
                            78.01,51.00 97.42,69.00 97.42,69.00
                            102.77,73.69 110.16,80.98 118.00,79.43
                            128.40,77.38 132.36,64.12 137.00,56.00
                            137.00,56.00 155.47,26.00 155.47,26.00
                            163.83,12.59 167.15,4.21 184.00,0.46
                            204.05,-2.31 228.38,5.57 243.96,18.21 Z" />
                </svg>
                {this.props.solo ? null : 
                    <span className="camel-name" style={camelNameStyle} onClick={this.props.onClick}>
                        {cp.name}
                    </span>
                }
            </div>
        );
    }
}
 
