import React, { useState } from 'react';
import { Button, Grid} from '@material-ui/core';
import './Desert.scss';

export default function Desert(props) {
    const [forward, setForward] = useState(true);
    const [square, setSquare] = useState(0);
    const backClass = forward ? "direction-btn" : "select-direction-btn";
    const foreClass = forward ? "select-direction-btn" : "direction-btn";
    const disabledForward = !([0, -1].includes(square)) && props.game.yourDesert === square && props.game.track[square].desert.forward === true;
    const disabledBackward = !([0, -1].includes(square)) && props.game.yourDesert === square && props.game.track[square].desert.forward === false;
    return (<div className="desert-container">
        <Grid container justify="center">
            <Grid container justify="center">
                <Button className={backClass} variant="contained" onClick={()=> {setForward(false)}} disabled={disabledBackward}>Backwards</Button>
                <Button className={foreClass} variant="contained" onClick={()=> {setForward(true)}} disabled={disabledForward}>Forwards</Button>
            </Grid>
            <Grid container justify="center">
                {props.game.canDesert.map(sq => {
                    const selectedClass = sq === square ? "select-square-btn" : "square-btn";
                    const key = `desert_${sq}`;
                    return (<Button 
                        variant="contained"
                        key={key}
                        className={selectedClass}
                        onClick={()=>{setSquare(sq)}}>
                            {sq}
                    </Button>);
                })}
            </Grid>
            <Button
                className="direction-btn"
                variant="contained"
                onClick={()=> {props.cb(square, forward)}}
                disabled={square === 0 || (disabledBackward && !forward) || (disabledForward && forward)}
            >
                Place Desert
            </Button>
        </Grid>
    </div>)
}