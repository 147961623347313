import React, { Component } from 'react';
import {
    Grid,
    Typography,
} from '@material-ui/core';
import './Scoreboard.scss';

/**
 * Use this to show current player's turn, and amount of money everyone has
 */
class ScoreboardComponent extends Component {
    render() {
        const youAreSub = this.props.game.players[this.props.game.youAre].sub;

        return (<div className="section">
            <Grid container justify="center" direction="column" alignItems="center">
                {
                    [...this.props.game.players]
                        .sort((p1, p2) => {
                            return p2.money - p1.money
                        })
                        .map((p) => {
                            return this.playerDetails(p, youAreSub)
                        })
                }
            </Grid>
        </div>)
    }

    /**
     * Render player details
     * @param {Player} p 
     * @param {Number} youAreSub - sub of current Player
     */
    playerDetails(p, youAreSub) {
        const name = p.sub === youAreSub ?
            (
                <b>{p.name}</b>
            ) :
            p.name;

        return (
            <div key={p.name} className="score-container">
                <Typography variant="h5">
                    {name}
                </Typography>
                <Typography variant="h5">
                    ${p.money}
                </Typography>
            </div>
        )
    }
}
export default ScoreboardComponent