import React, { Component } from 'react';
import './App.scss';
import { withRouter, useParams } from 'react-router-dom';
import service from './GameService';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grid, Typography, Input, Snackbar } from '@material-ui/core';
import Players from './Players';
import Track from './Track';
import FullscreenButton from './Fullscreen';
import Scoreboard from './Scoreboard';
import Desert from './Desert';
import Bet from './Bet';
import Moves from './Moves';
import Tutorial from './Tutorial';
import FloatingActions from './FloatingActions/FloatingActions';
import GameDialog from './GameDialog';
import Camel from './Camel';
import EditCamel from './EditCamel';
import { templates } from './FloatingActions/GameLog';
import './Game.scss';

const POLL_PERIOD = 2000 // milliseconds
const PHASE_INIT = "INIT"
const PHASE_PLAY = "PLAY"
const PHASE_OVER = "OVER"
const NUM_PLAYER_COLORS = 12;

const ACTIONMODALS = {
    NONE: 'none',
    LEGBET: 'legbet',
    WINNERBET: 'winnerbet',
    LOSERBET: 'loserbet',
    DESERT: 'desert',
}

class GameComponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            game: undefined,
            gameId: props.gameId,
            found: true,
            errorMessage: undefined,
            intervalId: undefined,
            awaitUpdate: false,
            isGuideComplete: true,
            showFireworks: true,
            actionModal: ACTIONMODALS.NONE,
            isDesertDialogVisible: false,
            isLegBetDialogVisible: false,
            isWinnerBetDialogVisible: false,
            isLoserBetDialogVisible: false,
            isCamelDialogVisible: false,
            camelSelected: 0,
            lastLog: -1,
        }
        this.poll = this.poll.bind(this);
        this.handleJoinGame = this.handleJoinGame.bind(this)
        this.handleStartGame = this.handleStartGame.bind(this)
        this.handleRestartGame = this.handleRestartGame.bind(this)
        this.handleAddBot = this.handleAddBot.bind(this)
        this.updateGameState = this.updateGameState.bind(this)
        this.handleMove = this.handleMove.bind(this)
        this.awaitUpdate = this.awaitUpdate.bind(this)
    }

    async componentDidMount() {
        try {
            const game = await service.getGame(this.state.gameId);

            if (game.message !== undefined) {
                this.setState({
                    errorMessage: game.message
                })
            }
            else {
                const playerColorMap = game.players.map(assignColors);
                const newState = {
                    game,
                    found: true,
                    errorMessage: undefined,
                    playerColorMap,
                    isGuideComplete: !((game.phase === PHASE_PLAY && game.currentPlayer === 0 && game.log.length === 0) || game.phase === PHASE_INIT),
                    lastLog: game.log.length - 1,
                }
                console.log('last log updated');

                this.setState(newState)
                if (!game.over) {
                    let intervalId = setInterval(this.poll, POLL_PERIOD)
                    this.setState({ intervalId })
                }
            }
        }
        catch (e) {
            console.log(e)
            this.setState({ found: false })
        }
    }

    componentWillUnmount() {
        this.clearPolling()
    }

    render() {
        if (!this.state.found || this.state.gameId === undefined) {
            return (<p>{this.state.gameId} Not Found!</p>)
        }
        else if (this.state.errorMessage !== undefined) {
            return (<p>{this.state.errorMessage} Not Found!</p>)
        }
        else if (this.state.game === undefined) {
            return (
                <div className="loading-screen">
                    <CircularProgress size="80px" />
                </div>
            )
        }

        if (this.state.game.phase === PHASE_INIT) {
            return (
                <div className="game-container game-not-started">
                    <Grid container alignItems="center" justify="space-evenly" direction="column" style={{'minHeight': '80%'}}>
                        {this.join()}
                        {this.start()}
                        <Grid container alignItems="flex-start" justify="space-evenly">
                            {this.selectCamels()}
                            <Players game={this.state.game} playerColorMap={this.state.playerColorMap}/>
                        </Grid>
                        {this.addBot()}
                    </Grid>
                    <FullscreenButton white></FullscreenButton>
                </div>
            )
        }

        if (!this.state.isGuideComplete) {
            const cb = () => { this.setState({isGuideComplete: true}) };
            return (<Tutorial cb={cb.bind(this)}/>);
        }

        return (
            <div className="game-container">
                {this.handlePlayPhase()}
                {this.handleScoreboardPhase()}
                <FullscreenButton></FullscreenButton>
            </div>
        )
    }
    

    handleScoreboardPhase = () => {
        if (this.state.game.phase !== PHASE_OVER) {
            return (null)
        }

        // If game is over, show who won
        if (this.state.game.phase === PHASE_OVER) {
            const winners = this.state.game.log.filter(l => l.type === 'win')[0].player.map(pin => this.state.game.players[pin].name);
            const winText = winners.length === 1 ? "Congratulations to the winner," : "Congratulations to the winners:"
            let winnerNameDisplay =  winners[0]; 
            if(winners.length > 1) {
                const lastWinner = winners[winners.length-1];
                const secondLastWinner = winners[winners.length-2];
                winners.splice(winners.length-2, 2, `${secondLastWinner} and ${lastWinner}`);
                console.log(winners);
                winnerNameDisplay = winners.join(', ');
            }

            return (
                <Grid container alignItems="center" justify="space-evenly" direction="column" className="game-over-container">
                    <Scoreboard game={this.state.game}/>
                    <Grid container justify="center" alignItems="center" direction="column" className="winner-section">
                        <Typography variant="h4">
                            <b>Game Over</b>
                        </Typography>
                        <Typography variant="h4" style={{textAlign:'center'}}>
                            {winText} <b>
                            {winnerNameDisplay}
                            </b>!
                        </Typography>
                        {this.restart()}
                    </Grid>
                    <div className="fireworks">
                        {/* 
                            Executing raw jQuery based on current state is non-trivial in React.
                            I tried a few different approaches, but ultimately hacked my way through by
                            injecting javascript via onError of a 'bad' image.

                            We need to only call .fireworks() once, else the browser suffers serious
                            performance issues.
                        */}
                        <img src='x' alt='' onError={() => {
                            if (this.state.showFireworks) {
                                window.$('.fireworks').fireworks({ count: 5 });
                                this.setState({showFireworks: false});
                            }
                        }}></img>
                    </div>
                </Grid>
            )
        }
    }
    
    handlePlayPhase = () => {
        if (this.state.game.phase !== PHASE_PLAY) {
            return (null);
        }

        let notifications = null;
        if (this.state.lastLog < this.state.game.log.length - 1) {
            notifications = this.state.game.log
                .filter((log, logIndex) => logIndex > this.state.lastLog && log.player !== this.state.game.youAre)
                .map((log, logIndex) => 
                    (
                        <Snackbar
                            key={`notification-${logIndex}`}
                            open={true}
                            message={(
                                <span style={{fontSize: '1.5rem'}}>
                                    {
                                        templates[log.type](log, this.state.game.players, this.state.playerColorMap, this.state.game.camels)
                                    }
                                </span>
                            )}
                            style={{
                                bottom: `${6*logIndex + 1}rem`,
                            }}
                            autoHideDuration={5000}
                            onClose={() => {
                                this.setState({lastLog: this.state.game.log.length - 1})
                            }}
                        />
                    )
                );
        }
        
        return (
            <Grid className="game-board">
                <Track game={this.state.game}/>
                <FloatingActions 
                    game={this.state.game}
                    playerColorMap={this.state.playerColorMap}
                />
                {this.actions()}
                <Moves game={this.state.game}/>
                {this.showActionModal()}
                {notifications}
            </Grid>
        )
    }

    actions() {
        if (this.state.game.currentPlayer === this.state.game.youAre) {
            const buttons = [
                {
                    label: 'Move',
                    selected: false,
                    onClick: () => {this.handleMove()}
                },
                {
                    label: 'Desert',
                    selected: this.state.actionModal === ACTIONMODALS.DESERT,
                    onClick: () => {
                        this.setState({
                            actionModal: ACTIONMODALS.DESERT,
                            isDesertDialogVisible: true
                        })}
                },
                {
                    label: 'Bet On Leg',
                    selected: this.state.actionModal === ACTIONMODALS.LEGBET,
                    onClick: () => {
                        this.setState({
                            actionModal: ACTIONMODALS.LEGBET,
                            isLegBetDialogVisible: true
                        })}
                },
                {
                    label: 'Bet On Winner',
                    selected: this.state.actionModal === ACTIONMODALS.WINNERBET,
                    onClick: () => {
                        this.setState({
                            actionModal: ACTIONMODALS.WINNERBET,
                            isWinnerBetDialogVisible: true
                        })}
                },
                {
                    label: 'Bet On Loser',
                    selected: this.state.actionModal === ACTIONMODALS.LOSERBET,
                    onClick: () => {
                        this.setState({
                            actionModal: ACTIONMODALS.LOSERBET,
                            isLoserBetDialogVisible: true
                        })}
                },
            ].map(b => {
                const key = `action_${b.label.replace(/\s/g, '')}`;
                const className = b.selected ? "select-modal-btn" : "modal-btn"
                const label = this.state.awaitUpdate ? (<CircularProgress />) : b.label;
                const onClick = b.selected ? () => {this.setState({actionModal: ACTIONMODALS.NONE})} : b.onClick;
                return (
                    <Button key={key} variant="contained"
                        className={className}
                        disabled={this.state.awaitUpdate}
                        onClick={onClick}>
                        {label}
                    </Button>
                );
            });
            return (<Grid className="action-btn-container">{buttons}</Grid>);
        }
        else {
            return (
                <Typography variant="h4">
                    Waiting for <b>{this.state.game.players[this.state.game.currentPlayer].name}</b> to finish their turn...
                </Typography>
            )
        }
    }

    showActionModal() {
        switch(this.state.actionModal) {
            case ACTIONMODALS.DESERT:
                return (
                    <GameDialog
                        isOpen={this.state.isDesertDialogVisible}
                        onClose={() => {this.setState({isDesertDialogVisible: false, actionModal: ACTIONMODALS.NONE})}}
                        title='Place A Desert Tile'
                        subTitle='
                            If a camel lands on your desert, they will either move forward
                            or backward one space, and you get $1. Desert tiles only last
                            for 1 leg.'
                    >
                        <Desert game={this.state.game} cb={this.handleDesert.bind(this)} />
                    </GameDialog>
                );
            case ACTIONMODALS.LEGBET:
                return (
                    <GameDialog
                        isOpen={this.state.isLegBetDialogVisible}
                        onClose={() => {this.setState({isLegBetDialogVisible: false, actionModal: ACTIONMODALS.NONE})}}
                        title='Bet On Leg'
                        subTitle='Choosing the wrong camel will cost you $1'
                    >
                        <Bet
                            game={this.state.game} 
                            cb={this.handleLegBet.bind(this)} 
                            availableBets={this.state.game.availableBets.leg}
                        />
                    </GameDialog>
                );
            case ACTIONMODALS.WINNERBET:
                return (
                    <GameDialog
                        isOpen={this.state.isWinnerBetDialogVisible}
                        onClose={() => {this.setState({isWinnerBetDialogVisible: false, actionModal: ACTIONMODALS.NONE})}}
                        title='Bet On Overall Winner'
                        subTitle='Who do you think will be the first to cross the finish line?'
                    >
                        <Bet
                            game={this.state.game} 
                            cb={this.handleWinnerBet.bind(this)} 
                            availableBets={this.state.game.availableBets.winner}/>
                    </GameDialog>
                );
            case ACTIONMODALS.LOSERBET:
                return (
                    <GameDialog
                        isOpen={this.state.isLoserBetDialogVisible}
                        onClose={() => {this.setState({isLoserBetDialogVisible: false, actionModal: ACTIONMODALS.NONE})}}
                        title='Bet On Overall Loser'
                        subTitle='Who will be last when the race is over?'
                    >
                        <Bet
                            game={this.state.game} 
                            cb={this.handleLoserBet.bind(this)} 
                            availableBets={this.state.game.availableBets.loser}/>
                    </GameDialog>
                );
            default:
                return (null);
        }
    }

    join() {
        if (this.state.game.canJoin) {
            return (
                <Button variant="contained"
                        className="join-game-btn"
                        disabled={this.state.awaitUpdate}
                        onClick={() => {this.handleJoinGame()}}>
                    {this.state.awaitUpdate ?
                        (<CircularProgress />) :
                        ('Join')
                    }
                </Button>
            )
        }
        else {
            return (null)
        }
    }

    start() {
        if (this.state.game.canStart) {
            return (
                <Button variant="contained"
                        className="start-game-btn"
                        disabled={this.state.awaitUpdate}
                        onClick={() => {
                            this.setState({showFireworks: true, lastLog: -1});
                            this.handleStartGame();
                        }}>
                    {this.state.awaitUpdate ?
                        (<CircularProgress />) :
                        ('Start')
                    }
                </Button>
            )
        }
        else {
            return (null)
        }
    }

    restart() {
        if (this.state.game.canStart) {
            return (
                <Button variant="contained"
                        className="start-game-btn"
                        disabled={this.state.awaitUpdate}
                        onClick={() => {
                            this.setState({showFireworks: true, lastLog: -1});
                            this.handleRestartGame();
                        }}>
                    {this.state.awaitUpdate ?
                        (<CircularProgress />) :
                        ('New Game With Same Players')
                    }
                </Button>
            )
        }
        else {
            return (null)
        }
    }

    updateSettings() {
        if (!this.state.game.canSetPoints) {
            return (
                <div className="section" style={{width: '50%'}}>
                    <Grid container alignItems="center" justify="center" direction="column">
                        <Typography variant="h4" className="settings-title">
                            Settings
                        </Typography>
                        <Typography variant="h5">
                            <b>Game Id: </b>{this.state.game.uuid}
                        </Typography>
                        <Typography variant="h5">
                            <b>Points To Win: </b>{this.state.game.points}
                        </Typography>
                    </Grid>
                </div>
            );
        }

        const handleInputChange = (event) => {
            this.setState({
                gameSettings: {
                    points: Number(event.target.value)
                }
            });
        };

        return (
            <div className="section" style={{width: '50%'}}>
                <Grid container alignItems="center" justify="center" direction="column">
                    <Typography variant="h4" className="settings-title">
                        Settings
                    </Typography>
                    <Typography variant="h5">
                        <b>Game Id: </b>{this.state.game.uuid}
                    </Typography>
                    <Typography variant="h5">
                        <b>Points To Win:</b>
                        <Input
                            className="points-input"
                            value={this.state.gameSettings.points}
                            margin="dense"
                            onChange={handleInputChange}
                            inputProps={{
                                step: 1,
                                min: 2,
                                max: 20,
                                type: 'number',
                            }}
                        />
                    </Typography>
                    <Button variant="contained"
                            className="update-settings-btn"
                            disabled={this.state.awaitUpdate}
                            onClick={() => {this.handleUpdateGameSettings()}}>
                        {this.state.awaitUpdate ?
                            (<CircularProgress />) :
                            ('Update Settings')
                        }
                    </Button>
                </Grid>
            </div>
        );
    }

    addBot() {
        if (this.state.game.phase === PHASE_INIT) {
            return (
                <Button variant="contained"
                        className="add-bot-btn"
                        disabled={this.state.awaitUpdate}
                        onClick={() => {this.handleAddBot()}}>
                    {this.state.awaitUpdate ?
                        (<CircularProgress />) :
                        ('Add Bot')
                    }
                </Button>
            )
        }
        else {
            return (null)
        }
    }

    selectCamels() {
        return (
            <div className="camel-edit-container">
                <Typography variant="h4">
                    Camel Corral
                </Typography>
                <div className="camel-corral">
                    {this.state.game.camels.map((camel, camelIndex) => {
                        return (
                            <Camel
                                key={`camel-${camelIndex}`}
                                color={camel.colorHex}
                                name={camel.name}
                                editable
                                onClick={() =>{this.setState({
                                    camelSelected: camelIndex,
                                    isCamelDialogVisible: true,
                                })}}
                            />
                        )
                    })}
                </div>
                <GameDialog
                    isOpen={this.state.isCamelDialogVisible}
                    onClose={() => {this.setState({isCamelDialogVisible: false})}}
                    title='Update Your Camel'
                >
                    <EditCamel
                        camelId={this.state.camelSelected}
                        game={this.state.game}
                        onClose={(camelId, name, color) => {
                            this.handleCamelUpdate(camelId, name, color)
                        }}
                    ></EditCamel>
                </GameDialog>
            </div>
        )
    }

    async handleJoinGame() {
        try {
            this.awaitUpdate(async () => {
                return await service.joinGame(this.state.gameId)
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleStartGame() {
        try {
            this.awaitUpdate(async () => {
                return await service.startGame(this.state.gameId)
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleRestartGame() {
        try {
            this.awaitUpdate(async () => {
                return await service.restartGame(this.state.gameId)
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleUpdateGameSettings() {
        try {
            this.awaitUpdate(async () => {
                return await service.updateGameSettings(this.state.gameId, this.state.gameSettings.points);
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleAddBot() {
        try {
            this.awaitUpdate(async () => {
                return await service.addBot(this.state.gameId);
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleMove() {
        try {
            this.awaitUpdate(async () => {
                this.setState({ actionModal: ACTIONMODALS.NONE });
                return await service.move(this.state.gameId);
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`)
        }
    }

    async handleDesert(square, forward) {
        try {
            this.awaitUpdate(async () => {
                this.setState({
                    actionModal: ACTIONMODALS.NONE,
                    isDesertDialogVisible: false,
                });
                return await service.desert(this.state.gameId, square, forward);
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`);
        }
    }

    async handleLegBet(camel) {
        try {
            this.awaitUpdate(async () => {
                this.setState({
                    actionModal: ACTIONMODALS.NONE,
                    isLegBetDialogVsible: false,
                });
                return await service.legBet(this.state.gameId, camel);
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`);
        }
    }

    async handleWinnerBet(camel) {
        try {
            this.awaitUpdate(async () => {
                this.setState({
                    actionModal: ACTIONMODALS.NONE,
                    isWinnerBetDialogVisible: false,
                });
                return await service.winnerBet(this.state.gameId, camel);
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`);
        }
    }

    async handleLoserBet(camel) {
        try {
            this.awaitUpdate(async () => {
                this.setState({
                    actionModal: ACTIONMODALS.NONE,
                    isLoserBetDialogVisible: false,
                });
                return await service.loserBet(this.state.gameId, camel);
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`);
        }
    }

    async handleCamelUpdate(camelId, camelName, camelColor) {
        if (["trump","donald"].includes( camelName.toLowerCase() )) {
            camelColor = "#f79239"; // Cheetos
        }

        try {
            this.awaitUpdate(async () => {
                this.setState({
                    isCamelDialogVisible: false,
                });
                return await service.updateCamels(this.state.gameId, camelId, {name: camelName, colorHex: camelColor});
            })
        }
        catch (e) {
            console.log(`${new Date().toISOString()}: ${e.message}`);
        }
    }


    /**
     * Poll for updates to the game
     */
    async poll() {
        // don't poll if the user needs to provide an input
        const awaitingUserInput = (this.state.game.phase === PHASE_PLAY && this.state.game.currentPlayer === this.state.game.youAre);
        if (!this.state.awaitUpdate && !awaitingUserInput) {
            try {
                const resp = await service.getGame(this.state.gameId)
                this.updateGameState(resp)
            }
            catch (e) {
                console.log(`${new Date().toISOString()}: ${e.message}`)
            }
        }
    }
    /**
     * Change game state as necessary
     * @param {Game} newGame
     * @param {boolean} gameStart - optional. if true, do dice init if appropiate
     */
    updateGameState(newGame) {
        const newState = {
            game: newGame,
            awaitUpdate: false,
        }
        this.setState(newState)
    }
    /**
     * Callback should return a game object. Sets the awaitUpdate flag to true before 
     * service returns, then false when data is returned
     * @param {async Function<GameFull>} callback 
     */
    async awaitUpdate(callback) {
        this.setState({ awaitUpdate: true })
        this.updateGameState(await callback())
    }
    /**
     * Stops the interval call, as necessary. This should only happen if the game is ended
     */
    clearPolling() {
        if (this.state.intervalId !== undefined) {
            clearInterval(this.state.intervalId)
            this.setState({ intervalId: undefined })
        }
    }
}

function assignColors(player, pin) {
    return `playerColor${pin % NUM_PLAYER_COLORS}`
}

export { GameComponent }

export default function Game() {
    let { gameId } = useParams()
    const GameComponentWithRouter = withRouter(GameComponent)
    return (<GameComponentWithRouter gameId={gameId} />)
}
