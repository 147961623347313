import React, { Component } from 'react';
import {
    Dialog,
    DialogTitle,
    IconButton,
    Icon,
    Slide,
    Typography
} from '@material-ui/core';
import './GameDialog.scss';


class GameDialogComponent extends Component {
    transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {
        return (
            <Dialog open={this.props.isOpen}
                    onClose={this.props.onClose}
                    TransitionComponent={this.transition}
                    className="game-dialog"
                    fullWidth={true}
                    maxWidth="md">

                <DialogTitle>
                    {this.props.title}
                </DialogTitle>

                {!this.props.subTitle ? null : 
                    <Typography variant="h5" className="sub-title">
                        {this.props.subTitle}
                    </Typography>
                }

                {this.props.children}

                <IconButton className="close-btn" onClick={this.props.onClose}>
                    <Icon>close</Icon>
                </IconButton>
            </Dialog>
        )
    };
}
export default GameDialogComponent