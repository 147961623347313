import React, { Component } from 'react';
import {
    Grid,
    Typography,
    Card,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Pyramid from './Pyramid';
import Camel from './Camel';
import './Track.scss';

/**
 * Show where things are on the board
 */
export default class TrackComponent extends Component {
    render() {
        const getSquare = (tsIndex) => {
            return this.trackDetails(this.props.game.track[tsIndex], tsIndex);
        }

        return (
            <div className="section">
                <Grid container className="track-container">
                    <Grid item container xs={12}>
                        <Grid item xs={2}> {getSquare(6)} </Grid>
                        <Grid item xs={2}> {getSquare(7)} </Grid>
                        <Grid item xs={2}> {getSquare(8)} </Grid>
                        <Grid item xs={2}> {getSquare(9)} </Grid>
                        <Grid item xs={2}> {getSquare(10)} </Grid>
                        <Grid item xs={2}> {getSquare(11)} </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={2}> {getSquare(5)} </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={2}> {getSquare(12)} </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={2}> {getSquare(4)} </Grid>
                        <Grid item xs={8}>
                            <Pyramid game={this.props.game}></Pyramid>
                        </Grid>
                        <Grid item xs={2}> {getSquare(13)} </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={2}> {getSquare(3)} </Grid>
                        <Grid item xs={8}></Grid>
                        <Grid item xs={2}> {getSquare(14)} </Grid>
                    </Grid>
                    <Grid item container xs={12}>
                        <Grid item xs={2}> {getSquare(2)} </Grid>
                        <Grid item xs={2}> {getSquare(1)} </Grid>
                        <Grid item xs={2}> {getSquare(0)} </Grid>
                        <Grid item xs={2}> </Grid>
                        <Grid item xs={2}> {this.finishTrackSquare()} </Grid>
                        <Grid item xs={2}> {getSquare(15)} </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

    /**
     * Render stuff on the track
     * @param {TrackSquare} ts 
     * @param {number} tsIndex
     */
    trackDetails(ts, tsIndex) {
        return (
            <Grid item className="track-square-container" key={'ts-' + tsIndex}>
                <Card variant="outlined" className="track-square-container">
                    <Typography className="track-square-title">
                        {tsIndex === 0 ? 'Start' : tsIndex}
                    </Typography>
                    {this.camelStack(ts.camels, tsIndex)}
                    {this.desert(ts.desert, tsIndex)}
                </Card>
            </Grid>
        );
    }

    finishTrackSquare() {
        return (
            <Grid item className="track-square-container">
                <Card variant="outlined" className="track-square-container finish-square">
                    <Typography className="track-square-title">
                        Finish
                    </Typography>
                    <div className="finish-flag"></div>
                </Card>
            </Grid>
        );
    }

    /**
     * 
     * @param {number[]} camels 
     * @param {number} squareIndex 
     */ 
    camelStack(camels, squareIndex) {
        if(camels === undefined) {
            return (null);
        }
        const cstack = camels.map((c,i) => {
                return (
                    <Camel
                        key={`camel_${c}`}
                        name={this.props.game.camels[c].name}
                        color={this.props.game.camels[c].colorHex}
                        trackIndex={squareIndex}
                        stackIndex={i}
                    />
                );
            })
        return (<Grid container spacing={1}>
            {cstack} 
        </Grid>);
    }

    /**
     * 
     * @param {number[]} camels
     * @param {number} tsIndex
     */ 
    desert(desert, tsIndex) {
        if(desert === undefined) {
            return (null);
        }

        let desertStyle = {};
        
        if(desert.forward) {
            if (tsIndex < 2 || tsIndex > 14) {
                // 'Forward' is left
                desertStyle.transform = 'rotateZ(180deg)';
            }
            if (tsIndex >= 2 && tsIndex <= 5) {
                // 'Forward' is up
                desertStyle.transform = 'rotateZ(-90deg)';
            }
            if (tsIndex >= 11 && tsIndex <= 14) {
                // 'Forward' is down
                desertStyle.transform = 'rotateZ(90deg)';
            }

            return (
                <div className="desert" style={desertStyle}>
                    <ArrowForwardIcon/>
                </div>
            );
        }
        else {
            if (tsIndex < 3) {
                // 'Backward' is right
                desertStyle.transform = 'rotateZ(180deg)';
            }
            if (tsIndex >= 3 && tsIndex <= 6) {
                // 'Backward' is down
                desertStyle.transform = 'rotateZ(-90deg)';
            }
            if (tsIndex >= 12) {
                // 'Backward' is up
                desertStyle.transform = 'rotateZ(90deg)';
            }

            return (
                <div className="desert" style={desertStyle}>
                    <ArrowBackIcon/> 
                </div>
            );
        }
    }
}
 