import React, { Component } from 'react';
import {
    Dialog,
    DialogTitle,
    IconButton,
    Icon,
    Slide,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Typography,
    Slider
} from '@material-ui/core';
import './GameSettingsDialog.scss';

class GameLogSettingsComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            positionY: this.props.positionY,
            size: this.props.size,
        }
    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.size !== props.size || current_state.positionY !== props.positionY) {
            return {
                size: props.size,
                positionY: props.positionY
            }
        }
        return null
    }

    transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    render() {
        return (
            <Dialog open={this.props.isOpen}
                    onClose={this.props.onClose}
                    TransitionComponent={this.transition}
                    className="game-settings-dialog"
                    fullWidth={true}
                    maxWidth="md">

                <DialogTitle>
                    Game Settings
                </DialogTitle>

                <Typography variant="h5">
                    Button Settings
                </Typography>
                <div className="button-settings-container">
                    <FormGroup row>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.props.isFlipped}
                                    onChange={this.props.updateFlipped}
                                    name="isFlipped" />}
                            label="Align Left"
                        />
                    </FormGroup>

                    <div>
                        <Typography id="positionY" gutterBottom>
                            Position
                        </Typography>
                        <Slider
                            value={this.state.positionY}
                            aria-labelledby="positionY"
                            step={1}
                            min={1}
                            max={60}
                            onChange={this.props.updatePositionY}
                            valueLabelDisplay="auto"
                        />
                    </div>

                    <div>
                        <Typography id="size" gutterBottom>
                            Size
                        </Typography>
                        <Slider
                            value={this.state.size}
                            aria-labelledby="size"
                            step={0.5}
                            min={2}
                            max={6}
                            onChange={this.props.updateSize}
                            valueLabelDisplay="auto"
                        />
                    </div>
                </div>

                <IconButton className="close-btn" onClick={this.props.onClose}>
                    <Icon>close</Icon>
                </IconButton>
            </Dialog>
        )
    };
}
export default GameLogSettingsComponent