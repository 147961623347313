import React from 'react';
import {
    Dialog,
    DialogTitle,
    IconButton,
    Icon,
    Slide,
    Grid,
    Card,
    Typography,
} from '@material-ui/core';
import './BetInfoDialog.scss';
import { MysteryCamel } from '../CamelPersonalizations';
import Camel from '../Camel';


const transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BetInfoDialog(props) {
    const betByPlayer = props.game.players.map((p, pin) => {
        const legBets = extractBets(props.game.legBets, pin, 'Leg');
        const winnerBets = extractBets(props.game.overallWinnerBets, pin, 'Winner');
        const loserBets = extractBets(props.game.overallLoserBets, pin, 'Loser');
        return legBets.concat(winnerBets).concat(loserBets);
    }).map((bets, pin) => {
        const key = `bet_info_${pin}`;
        const name = pin === props.game.youAre ? (<b>{props.game.players[pin].name}</b>) : props.game.players[pin].name;
        const betJsx = bets.length === 0 ? (<span>No Bets</span>) : bets.map((b, bin) => {
            const cp = (undefined === b.camel) ? MysteryCamel : props.game.camels[b.camel];
            const key = `bet_info_${pin}_${bin}`
            return (<Card 
                className="camel-card"
                key={key}>
                    <span>
                        <Camel
                            name={cp.name}
                            color={cp.colorHex}
                            solo
                        />
                    </span>
                    <span>{cp.name}</span><span>{b.value}</span><span>{b.type}</span>
            </Card>);
        });
        return (<div key={key} className="bet-player-container">
                <Typography variant="h5">
                    {name}
                </Typography>
                <Grid container>
                    {betJsx}
                </Grid>
            </div>);
    });
    return (
        <Dialog open={props.isOpen}
                onClose={props.onClose}
                TransitionComponent={transition}
                className="bet-info-dialog"
                fullWidth={true}
                maxWidth="md">

            <DialogTitle>
                Bets Placed
            </DialogTitle>

            {betByPlayer}

            <IconButton className="close-btn" onClick={props.onClose}>
                <Icon>close</Icon>
            </IconButton>
        </Dialog>
    )
}

function extractBets(betArray, pin, type) {
    return betArray.filter(b => b.owner === pin).map(b => {
        b.type = type;
        return b;
    });
}