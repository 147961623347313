import { Auth } from 'aws-amplify'
const SERVICE_BASE_URL = getServiceUrl()

export default {
    getGame: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}`, {
            headers: { ...(await this.authHeader()) },
        })
        return await resp.json()
    },

    createGame: async function() {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/create`, {
            method: 'POST',
            headers: { ...(await this.authHeader()) },
        })
        return await resp.json()
    },

    joinGame: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/join`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
        })
        return await resp.json()
    },

    startGame: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/start`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
        })
        return await resp.json()
    },

    restartGame: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/restart`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
        })
        return await resp.json()
    },

    addBot: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/bot`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
        })
        return await resp.json()
    },
    /**
     * makes the camels move
     * @param {string} gameId 
     */
    move: async function(gameId) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/move`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
        })
        return await resp.json()
    },
    /**
     * plays a desert tile
     * @param {string} gameId 
     * @param {number} trackLocation - where on the track to play this tile
     * @param {boolean} forward - true if this tile pushes camels forward.
     */
    desert: async function(gameId, trackLocation, forward) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/desert`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({trackLocation, forward})
        })
        return await resp.json()
    },
    /**
     * bets on a camel for this leg
     * @param {string} gameId 
     * @param {number} camel 
     */
    legBet: async function(gameId, camel) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/bet/leg`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({camel})
        })
        return await resp.json()
    },
    /**
     * bets on a camel for overall winner
     * @param {string} gameId 
     * @param {number} camel 
     */
    winnerBet: async function(gameId, camel) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/bet/winner`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({camel})
        })
        return await resp.json()
    },
    /**
     * bets on a camel for overall loser
     * @param {string} gameId 
     * @param {number} camel 
     */
    loserBet: async function(gameId, camel) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/bet/loser`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({camel})
        })
        return await resp.json()
    },

    updateCamels: async function(gameId, camelId, camel) {
        let resp = await fetch(`${SERVICE_BASE_URL}/game/${gameId}/camels/${camelId}`, {
            method: 'POST',
            headers: { 
                ...(await this.authHeader()), 
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({camel})
        })
        return await resp.json()
    },

    authHeader: async function() {
        const authdata = await Auth.currentSession()
        return {
            'Authorization': `Bearer ${authdata.getIdToken().getJwtToken()}`
        }
    },
}

function getServiceUrl() {
    return "https://api.marketplaceofgames.com/derby"
}
