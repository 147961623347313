import React, { Component } from 'react';
import './Pyramid.scss';


class PyramidComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            animate: false,
            move: this.props.game.log.length === 0 ? null : this.props.game.log[this.props.game.log.length - 1].move
        }
    }

    componentDidUpdate(prevProps) {
        const logs = this.props.game.log;
        if(logs.length !== prevProps.game.log.length)
        {
            if (logs.length > 0 && logs[logs.length - 1].type === "move") {
                this.setState({
                    move: logs[logs.length - 1].move
                });
                this.onPyramidClick();
            }
        }
    }

    onPyramidClick = () => {
        this.setState({
            animate: true
        });

        setTimeout(() => {
            this.setState({
                animate: false
            });
        }, 5000);
    }

    render() {
        let pyramidClass = "pyramid-container";
        if (this.state.animate) {
            pyramidClass += " animate";
        }

        const diceText = this.state.move ? this.state.move.distance : "";
        const diceStyle = this.state.move ? {'backgroundColor': this.props.game.camels[this.state.move.camel].colorHex} : {};

        return (
            <div className={pyramidClass} onClick={this.onPyramidClick}>
                <div className="pyramid">
                    <div className="side left"></div>
                    <div className="side front"></div>
                    <div className="side right"></div>
                    <div className="side back"></div>
                </div>
                
                <div className="shadow"></div>

                <div className="scene">
                    <div className="cube">
                        <div className="cube__face cube__face--front" style={diceStyle}>{diceText}</div>
                        <div className="cube__face cube__face--back" style={diceStyle}>{diceText}</div>
                        <div className="cube__face cube__face--right" style={diceStyle}>{diceText}</div>
                        <div className="cube__face cube__face--left" style={diceStyle}>{diceText}</div>
                        <div className="cube__face cube__face--top" style={diceStyle}>{diceText}</div>
                        <div className="cube__face cube__face--bottom" style={diceStyle}>{diceText}</div>
                    </div>
                </div>
            </div>
        )
    }
}
export default PyramidComponent