import React from 'react';
import {
    TimelineItem,
    TimelineSeparator,
    TimelineDot,
    TimelineConnector,
    Timeline,
    TimelineContent
} from '@material-ui/lab';
import './GameLog.scss';
import { Icon } from '@material-ui/core';

    
export function GameLog(props) {
    if(props.game.log.length > 0) {
        const gameEventsToShow = props.game.log.map((gameLog, i) => {
            const key = `gameLogEvent${i}`;
            return(
                <TimelineItem key={key}>
                    {GameLogStringify(props, gameLog)}
                </TimelineItem>
            );
        }).reverse();

        return (
            <Timeline>
                {gameEventsToShow}
            </Timeline>
        )
    }
    else {
        return (null)
    }
}

function GameLogStringify(props, gameLogObject) {
    if(templates[gameLogObject.type] !== undefined) {
        const content = templates[gameLogObject.type](
            gameLogObject,
            props.game.players,
            props.playerColorMap,
            props.game.camels);
        const icon = templateIcons[gameLogObject.type];

        return (
            <>
            <TimelineSeparator>
                <TimelineDot className={props.playerColorMap[gameLogObject.playerIndex]}
                             variant="outlined">
                    <Icon fontSize="small">
                        {icon}
                    </Icon>
                </TimelineDot>
                <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
                {content}
            </TimelineContent>
            </>
        )
    }
    return JSON.stringify(gameLogObject)
}

export const templates = {
    move: (log, players, playerColorMap, camels) => {
        return (<span><b className={playerColorMap[log.player]}>{players[log.player].name}</b> moved <span style={{color: camels[log.move.camel].colorHex, fontWeight: 'bold'}}>{camels[log.move.camel].name}</span> {log.move.distance} space{log.move.distance > 1 ? 's':''}</span>)
    },
    legbet: (log, players, playerColorMap, camels) => {
        return (<span><b className={playerColorMap[log.player]}>{players[log.player].name}</b> placed a ${log.value} bet on <span style={{color: camels[log.camel].colorHex, fontWeight: 'bold'}}>{camels[log.camel].name}</span> winning the leg</span>)
    },
    desert: (log, players, playerColorMap, camels) => {
        return (<span><b className={playerColorMap[log.player]}>{players[log.player].name}</b> placed a desert on space {log.location}</span>)
    },
    winnerBet: (log, players, playerColorMap, camels) => {
        return (<span><b className={playerColorMap[log.player]}>{players[log.player].name}</b> placed a ${log.value} bet on the overall winner</span>)
    },
    loserBet: (log, players, playerColorMap, camels) => {
        return (<span><b className={playerColorMap[log.player]}>{players[log.player].name}</b> placed a ${log.value} bet on the overall loser</span>)
    },
};

const templateIcons = {
    move: "play_arrow",
    legbet: "monetization_on",
    desert: "brightness_high",
    winnerBet: "monetization_on",
    loserBet: "monetization_on",
};
