import React, { Component } from 'react';
import {
    Icon,
    IconButton
} from '@material-ui/core';
import './Fullscreen.scss';


class FullscreenButtonComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fullscreen: document.fullscreenElement != null
        }
    }

    render() {
        const classes = `fullscreen-btn ${this.props.white ? 'white-btn': ''}`;

        return (
            <IconButton onClick={this.handleFullscreenToggle} className={classes}>
                <Icon fontSize="large">
                    {this.state.fullscreen ? 'fullscreen_exit' : 'fullscreen'}
                </Icon>
            </IconButton>
        );
    };

    handleFullscreenToggle = (event) => {
        if (!this.state.fullscreen) {
            document.getElementsByTagName("body")[0].requestFullscreen();
        } else {
             document.exitFullscreen();
        }
        this.setState({
             fullscreen: !this.state.fullscreen
        });
    };
}
export default FullscreenButtonComponent