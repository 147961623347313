import React  from 'react';
import { Card, Grid} from '@material-ui/core';
import Camel from './Camel';
import './Moves.scss';

export default function Moves(props) {
    const camelCards = [];
    props.game.camels.forEach((camel, i) => {
        const key = `moves_${i}`;
        const moveIndex = props.game.moves.list.findIndex(m => m.camel === i);
        const move = moveIndex === -1 ? '' : `${props.game.moves.list[moveIndex].distance}`;

        camelCards.push((
            <Card className="score-card" key={key}>
                <Camel
                    name={camel.name}
                    color={camel.colorHex}
                    solo
                />
                <div className="camel-name">
                    {camel.name}
                </div>
                <div className="move-count">
                    {move}
                </div>
            </Card>));
    });
    
    return (<div className="bet-container">
        <Grid container>
            <h3 className="moves-title">Moves This Leg</h3>
            <Grid container justify="center">
                {camelCards}
            </Grid>
        </Grid>
    </div>)
}